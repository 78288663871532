import React from 'react';
import {useTranslation} from 'react-i18next';
import Emoji from '../../../components/emoji/Emoji';
import Modal from '../../../components/modal/Modal';
import '../contactsPage.css';
import ReactGA from 'react-ga';
import {ModalTextColorLink} from '../../../components/globalStyles';

export default function ModalContacts({
  MAX_PHONE_NUM,
  VLAD_PHONE_NUM,
  modalOpen,
  setModalOpen,
}) {
  const {t} = useTranslation();

  return (
    <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div id="modalContactsBox" className="telephoneContainer">
        <p id="modalContactsTitle" className="tapForCall">
          {t('tapCall')}:
        </p>
        <div id="modalContactsMaxPhoneNumberBox" className="numberCont">
          <Emoji symbol="📲" label="phone" />
          <ModalTextColorLink
            id="modalContactsNumberLinkMax"
            href={MAX_PHONE_NUM ? `tel:${MAX_PHONE_NUM[0]}` : '#'}
            className="telephone"
            onClick={() => {
              ReactGA.event({
                category: 'callMax',
                action: 'call to Max',
              });
            }}>
            {t('telMax')}: {MAX_PHONE_NUM ? MAX_PHONE_NUM[1] : t("error")}
          </ModalTextColorLink>
        </div>
        <div id="modalContactsDimaPhoneNumberBox" className="numberCont">
          <Emoji symbol="📲" label="phone" />
          <ModalTextColorLink
            id="modalContactsNumberLinkDima"
            href={MAX_PHONE_NUM ? `tel:${VLAD_PHONE_NUM[0]}` : '#'}
            className="telephone"
            onClick={() => {
              ReactGA.event({
                category: 'callDima',
                action: 'call to Dima',
              });
            }}>
            {t('telVlad')}: {VLAD_PHONE_NUM ? VLAD_PHONE_NUM[1] : t("error")}
          </ModalTextColorLink>
        </div>
      </div>
    </Modal>
  );
}
