import React from "react";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./stationNumber.css";
import { DistanceKm } from "../DistanceKm";
import { ModalTextColorLink } from "../globalStyles";

export default function StationNumber({
  station,
  costPerKwt,
  justifyContent,
  distanceMeters,
  latitude,
  longitude,
  plugshareLink,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Row
        id="stationNumberBox"
        className={`${justifyContent} mb-1 station`}
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <p id="stationNumberTitle" className="stationText">
          {t("station")}{" "}
        </p>
        <p id="stationNumberText" className="stationNumber">
          {station}
        </p>
      </Row>
      <Row
        id="stationNumberBox"
        className={`${justifyContent} mb-1 station`}
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <p id="startPageCostInfo" style={{ marginBottom: "0" }}>
          {costPerKwt}
          {t("costPerKwt")}
        </p>
      </Row>
      <Row
        className={`${justifyContent}`}
        style={{ marginLeft: "0", marginRight: "0" }}
      >
        <div>
          {latitude && longitude && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "8px",
              }}
            >
              <DistanceKm
                distanceMeters={distanceMeters}
                latitude={latitude}
                longitude={longitude}
              />
            </div>
          )}
        </div>
        <div>
          {plugshareLink && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ModalTextColorLink
                href={plugshareLink}
                onClick={(e) => e.stopPropagation()}
                style={{ textDecoration: "none", marginLeft: "8px" }}
              >
                <div
                  style={{
                    padding: "7px",
                    backgroundColor: "#d2b8d4",
                    color: "#FAFAFA",
                    fontWeight: "600",
                    width: "90px",
                    height: "35px",
                    fontSize: "14px",
                    borderRadius: "11px",
                  }}
                >
                  <p className="mb-0 align-self-center text-center">
                    PlugShare
                  </p>
                </div>
              </ModalTextColorLink>
            </div>
          )}
        </div>
      </Row>
    </>
  );
}
