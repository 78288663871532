import moment from "moment-timezone";

export const isWorkingTime = (
  station,
  openFrom,
  closedFrom,
  isTwentyFourHour,
) => {
  if (isTwentyFourHour) return true;
  const [openTimeHours, openTimeMinutes] = openFrom.split(":");
  const [closeTimeHours, closeTimeMinutes] = closedFrom.split(":");
  const currentTime = moment().tz(`${station?.tzdb}`);
  const timeForOpen = moment()
    .set({
      h: openTimeHours,
      m: openTimeMinutes,
      s: 0,
    })
    .tz(`${station?.tzdb}`);

  const checkNextDay = openTimeHours > closeTimeHours ? 1 : 0;

  const timeForClose = moment()
    .set({
      h: closeTimeHours,
      m: closeTimeMinutes,
      s: 0,
    })
    .add(checkNextDay, "d")
    .tz(`${station?.tzdb}`);

  const resultWorkTime =
    currentTime.isBefore(timeForOpen, "h") ||
    currentTime.isAfter(timeForClose, "h");

  if (resultWorkTime) {
    return false;
  } else {
    return true;
  }
};

export const getDate = (seconds) => {
  const getSeconds = Number(seconds);
  const getHours = getSeconds / 3600;
  return new Date(getSeconds * 1000)
    .toISOString()
    .slice(11, 19)
    .match(/\d+/g)
    .map((elem, index) => {
      if (index === 0) {
        return (elem = Math.floor(getHours).toFixed());
      }
      return elem;
    });
};
