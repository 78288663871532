import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {VLAD_PHONE_NUM, MAX_PHONE_NUM} from "../contactsPage/ContactsPage";
import ModalContacts from "../contactsPage/modal/ModalContacts";
import "./ErrorPage.css";
import "../../components/buttons/mainBtn.css";
import {Container, Row} from "react-bootstrap";
import BtnStartAgain from "../../components/buttons/BtnStartAgain";
import {Link} from "react-router-dom";

const ErrorPage = ({errorHeader, errorBody, startAgain, stationNumber}) => {
    const [isStartAgain, setIsStartAgain] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        if (startAgain === true) {
            setIsStartAgain(true);
        }
    }, [startAgain]);

    return (
        <Container
            id="errorPage"
            fluid="lg"
            className="d-flex align-items-center mt-3"
        >
            <div className="mx-auto">
                <p id="errorPageHeader" className="title">
                    {errorHeader}
                </p>
                <p id="errorPageBody" className="body">
                    {errorBody}
                </p>

                {isStartAgain ? (
                    <BtnStartAgain stationNumber={stationNumber}/>
                ) : (
                    <Row
                        onClick={() => setModalOpen(true)}
                        className="mainBtn mb-1 mt-4">
                        <p
                            id="errorPageModalPhoneText"
                            style={{textAlign: "center"}}
                            className="mainBtnText"
                        >
                            {t("helpCall")}
                        </p>
                    </Row>
                )}
                <Row
                    as={Link}
                    to={`/?station=${stationNumber}`}
                    className="mainBtn mb-1 mt-4">
                    <p className="mainBtnText ">{t('btns.getNearestStationsStatus')}</p>
                </Row>
            </div>

            <div id="errorPageModalContainer" style={{textAlign: "left"}}>
                <ModalContacts
                    MAX_PHONE_NUM={MAX_PHONE_NUM}
                    VLAD_PHONE_NUM={VLAD_PHONE_NUM}
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                />
            </div>
        </Container>
    );
};

export default ErrorPage;
