import * as React from "react";
export const SVGNightMode = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.000000pt"
    height="23.000000pt"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M1930 4444 c-341 -120 -671 -365 -895 -662 -443 -589 -519 -1378 -196 -2037 416 -850 1359 -1278 2277 -1035 612 162 1124 635 1333 1233 27 77 29 88 13 83 -235 -69 -543 -93 -792 -61 -311 39 -588 142 -845 313 -492 328 -798 834 -866 1432 -23 203 1 506 56 710 8 30 13 55 12 56 -1 1 -45 -13 -97 -32z" />
    </g>
  </svg>
);
