import { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import NotFoundPage from "./pages/notFoundPage/NotFoundPage";
import PrivateRoute from "./pages/startPage/privateRoute/PrivateRoute";
import HomePage from "./pages/homePage/HomePage";
import StartPage from "./pages/startPage/StartPage";
import ChargingPage from "./pages/chargingPage/ChargingPage";
import ContactsPage from "./pages/contactsPage/ContactsPage";
import TermsPage from "./pages/termsPage/TermsPage";
import ReactGA from "react-ga";
import B2bPage from "./pages/b2bPage/B2bPage";
import RedirectLogin from "./pages/b2bPage/redirectLogin/RedirectLogin";
import { ENV } from "./environment";
import StationNumberLayout from "./components/layouts/stationNumberLayout/StationNumberLayout";

const TRACKING_ID = {
  prod: "UA-246535650-1",
  stage: "UA-246535650-2",
};
// eslint-disable-next-line
if (ENV === "prod") {
  ReactGA.initialize(TRACKING_ID.prod);
} else if (ENV === "stage") {
  ReactGA.initialize(TRACKING_ID.stage);
}

function App() {
  return (
    <Suspense fallback={null}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/oauth2/redirect" element={<RedirectLogin />} />
            <Route path="/b2b" element={<B2bPage />} />
            <Route path="/contract" element={<TermsPage />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route element={<StationNumberLayout />}>
              <Route path="/charging" element={<ChargingPage />} />
              <Route
                path="/start"
                element={
                  <PrivateRoute>
                    <StartPage />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path="/" element={<HomePage />} />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
