import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material";

export const CustomSlider = styled(Slider)({
  color: "#8f00ff",
  marginTop: "25px",

  "& .MuiSlider-thumb": {
    width: "20px",
    height: "40px",
    borderRadius: "6px",
  },
});

export const CustomBox = styled(Box)({
  width: "90%",
  maxWidth: "25rem",
  padding: "0 15px 15px 15px",
  cursor: "pointer",
});
