export function changeB2bParams(checkedMonths, setCheckedMonths,
    checkedStations, setCheckedStations) {
  const updateParams = (value, action) => {
    const arr = value.includes("-")
        ? checkedMonths
        : checkedStations;
    const set = value.includes("-")
        ? setCheckedMonths
        : setCheckedStations;
    if (arr) {
      switch (action) {
        case "add":
          if (!arr.includes(value)) {
            set((arr) => [...arr, value])
          }
          break;
        case "delete":
          const updated = arr.filter((el) => el !== value);
          set(updated)
          break;
        case "clear":
          set([])
          break;
        default:
          break;
      }
    }
  };

  return {
    addParam: (value) => updateParams(value, "add"),
    deleteParam: (value) => updateParams(value, "delete"),
    clearParams: (value) => updateParams(value, "clear"),
  };
}
