import axios from "axios";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {kmData} from "./kmData";
import Modal from "../../../../components/modal/Modal";
import "./modalCalibration.css";
import ReactGA from "react-ga";

export default function ModalCalibrate({
  chargedKm,
  stationNumber,
  modalOpen,
  setModalOpen
}) {
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const [calibratedKm, setCalibratedKm] = useState(null);
  const roundChargedKm = chargedKm <= 10 ? 10 : Math.round(chargedKm / 10) * 10;

  const {t} = useTranslation();

  const btnStyle = value !== null ? "btnSend repeat" : "disableBtn btnSend";

  const urlV2Calibrating = `${process.env.REACT_APP_LINK_SERVE}device/v2/station/calibrate?`;

  const calibrateResult = async () => {
    await axios
    .get(
        `${urlV2Calibrating}stationNumber=${stationNumber}&deviceFingerPrint=${'fingerPrint'}&realKm=${value}`
    )
    .catch(function (error) {
      setError(error.message);
      console.log(error.message);
    })
    .then(function (result) {
      setCalibratedKm(result);
    });
  };

  const tryMore = () => {
    setError(null);
  };

  let scrollLet = value === null ? roundChargedKm : value;
  useEffect(() => {
    const element = document.getElementById(`${scrollLet / 10 - 1}`);
    element?.scrollIntoView({behavior: "smooth", block: "center"});
    setValue((Number(element?.id) + 1) * 10);
    console.log(element?.id);
    // eslint-disable-next-line
  }, [modalOpen]);

  return (
      <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <div id="modalCalibrateBox" className="calibrationCont">
          {error ? (
              <>
                <p id="modalCalibrateErrorText" className="calibrationText">
                  {t("errorDevHeader")}
                </p>
                <div
                    id="modalCalibrateErrorRepeatBtn"
                    onClick={tryMore}
                    className={btnStyle}
                >
                  {t("btnRepeat")}
                </div>
              </>
          ) : (
              <>
                {calibratedKm ? (
                    <div id="modalCalibrateSuccessBox">
                      <p
                          id="modalCalibrateCongratsTitle"
                          className="calibrationSuccess"
                      >
                        {t("chargedCongrats")}
                      </p>
                      <p
                          id="modalCalibrateCongratsText"
                          className="calibrationSuccess"
                      >
                        {t("calibratedKm")}
                      </p>
                    </div>
                ) : (
                    <>
                      <p
                          id="modalCalibrateCalibrationTitle"
                          className="calibrationTitle"
                      >
                        {t("calibration")}
                      </p>
                      <p
                          id="modalCalibrateCalibrationText"
                          className="calibrationText"
                      >
                        {t("enterYourKm")}:
                      </p>
                      <ul id="modalCalibrateKmList" className="selectBox">
                        {kmData.map((n, index) => (
                            <li
                                id={`modalCalibrate${n}KmElement`}
                                className={n === value ? "listKm celected"
                                    : "listKm"}
                                onClick={() => setValue(n)}
                                key={index}
                            >
                              {n}
                            </li>
                        ))}
                      </ul>

                      <div
                          id="modalCalibrateStartCalibrationBtn"
                          onClick={() => {
                            calibrateResult();
                            ReactGA.event({
                              category: "calibration",
                              action: "do calibration",
                            });
                          }}
                          className={btnStyle}
                      >
                        {t("sendKm")}
                      </div>
                    </>
                )}
              </>
          )}
        </div>
      </Modal>
  );
}
