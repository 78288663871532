import React from "react";
import styles from "./Modal.module.css";
import { ModalBg } from "../globalStyles";

export default function Modal({children, modalOpen, setModalOpen}) {
  return (
      <div
          id="modalContainer"
          className={modalOpen ? styles.modalActive : styles.modal}
          onClick={() => setModalOpen(false)}
      >
        <ModalBg
            id="modalBox"
            className={
              modalOpen ? styles.modalContentActive : styles.modalContent
            }
            onClick={(e) => e.stopPropagation()}
        >
          {children}
        </ModalBg>
      </div>
  );
}
