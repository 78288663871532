import React from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Chart} from "./Chart";
import {
  FullInfoContainer,
  PowerMetricsColor
} from "../../../components/globalStyles";
import "../getPower/GetPower.css";

export default function FullInfo({
  lastJob,
  kWtPower,
  chargeStatus,
  onlineStatus,
}) {
  const {t} = useTranslation();

  return (
          <FullInfoContainer id="fullInfoContainer" className="fullInfoCont">
            <Row id="fullInfoChartBox" className="justify-content-center mb-2">
              <Chart
                  power={
                    onlineStatus === false
                        ? 0
                        : Number(lastJob?.powerWt) / 1000
                  }
                  voltage={
                    onlineStatus === false
                        ? 0
                        : Number(Math.round(lastJob?.voltage))
                  }
              />
            </Row>
            <Row id="fullInfoKwtMetricsBox" className="justify-content-center">
              <Col id="fullInfoKwtChargedBox" className="text-center mb-2">
                <PowerMetricsColor id="fullInfoKwtChargedTitle"
                                   className="mb-0 textTitle voltTitle">
                  {t("charging")}
                </PowerMetricsColor>
                <p id="fullInfoKwtChargedInfo"
                   className="textInfo voltTitle text">
                  {chargeStatus} {t("wt")}
                </p>
              </Col>
              <Col id="fullInfoKwtPowerBox" xs lg={6} className="text-center">
                <PowerMetricsColor id="fullInfoKwtPowerTitle"
                                   className="mb-0 textTitle voltTitle">
                  {t("power")}
                </PowerMetricsColor>
                <p id="fullInfoKwtPowerInfo"
                   className="textInfo voltTitle text">
                  {lastJob?.deviceOnline === false ? (
                      <>{t("onlineStatus")}</>
                  ) : (
                      <>
                        {kWtPower.toFixed(2)} {t("powerWt")}
                      </>
                  )}
                </p>
              </Col>
            </Row>
          </FullInfoContainer>
  );
}
