import React from 'react';
import '../../components/buttons/mainBtn.css';
import './startPage.css';
import {Trans, useTranslation} from 'react-i18next';
import {Row} from 'react-bootstrap';
import Modal from '../../components/modal/Modal';
import {Link} from 'react-router-dom';

export default function ModalDelayedStart({
  stationNumber,
  setError,
  kwt,
  openFrom,
  openPaymentLink,
  modalOpen,
  setModalOpen,
}) {
  const {t} = useTranslation();

  return (
    <Modal modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <h1 className="delayedStartWarning">
        <Trans i18nKey="delayedStart">
          Charging will begin at {{openFrom}}
        </Trans>
      </h1>
      <Row
        as={'div'}
        className="mainBtn mb-1 mt-4"
        onClick={() => {
          openPaymentLink(stationNumber, kwt,  setError);
        }}>
        <p className="mainBtnText ">{t('btnPay')}</p>
      </Row>
      <Row
        as={Link}
        to={`/?station=${stationNumber}`}
        className="mainBtn mb-1 mt-4">
        <p className="mainBtnText ">{t('btns.getNearestStationsStatus')}</p>
      </Row>
    </Modal>
  );
}
