import {Link} from 'react-router-dom';
import './userInfo.css';
import {Dropdown} from 'react-bootstrap';
import {DropDownMenu, Text} from '../../../components/globalStyles';
import {useTranslation} from 'react-i18next';
import {api} from '../../../redux/api';
import {ENV, HOST, PORT} from '../../../environment';

const logoutUser = async (token, logout, fingerPrint) => {
  const local = ENV === 'local' ? ':3000' : '';
  const {isSuccess} = await logout({userToken: token, fingerPrint});
  if (isSuccess) {
    localStorage.removeItem('session_id');
    window.open(
      `${HOST}:${PORT}/oauth2/authorization/keycloak?redirect_uri=${HOST}${local}/oauth2/redirect`,
      '_self',
    );
  }
};

export default function UserInfo({userInfo, token, fingerPrint}) {
  const [logout] = api.useLazyUserLogoutQuery();
  const {t} = useTranslation();
  return (
    <div id="userInfoBox" className="infoBox">
      <Dropdown id="userInfoDropdownContainer">
        <Dropdown.Toggle id="userInfoDropdownBtn" className="toggleStyle">
          {userInfo?.imageUrl ? (
            <img
              id="userInfoImg"
              width="50"
              height="50"
              style={{borderRadius: '16px', position: 'fixed', top: '80px', right: '20px'}}
              src={`${userInfo?.imageUrl}`}
              alt="user"
            />
          ) : null}
        </Dropdown.Toggle>

        <DropDownMenu id="userInfoDropDownMenu" className="menuToggle">
          <Text id="userInfoNameField" className="userText">
            {t('userInfoName')}:
          </Text>
          <Text id="userInfoName">
            <b>{userInfo?.name}</b>
          </Text>
          <Text id="userInfoEmailField" className="userText">
            E-mail:
          </Text>
          <Text id="userInfoEmail">
            <b>{userInfo?.email}</b>
          </Text>
          <Link
            id="userInfoLogoutBtn"
            className="logoutBtn"
            onClick={() => {
              logoutUser(token, logout, fingerPrint);
            }}
            to="/b2b">
            {t('logout')}
          </Link>
        </DropDownMenu>
      </Dropdown>
    </div>
  );
}
