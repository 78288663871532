export async function getGeolocationWithPermission(getAllStations, handleGeolocationError, handleGeolocationSuccess) {
    if (navigator.permissions && navigator.permissions.query) {
        return navigator.permissions
            .query({name: "geolocation"})
            .then(result => {
                if (result.state === "granted") {
                    return navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
                } else if (result.state === "prompt") {
                    return new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
                    });
                } else {
                    handleGeolocationError("Permission denied");
                    return getAllStations();
                }
            })
            .catch(error => {
                if (error.code === 1) {
                    return getAllStations();
                }
            });
    } else {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
        }).catch(error => {
            console.error("Error getting geolocation:", error);
            return getAllStations();
        });
        ;
    }
}