import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOST, PORT } from "../environment";
import { getClientId } from "../hookServices/clientId";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: `${HOST}:${PORT}` }),
  endpoints: (builder) => ({
    deviceStart: builder.query({
      query: ({ stationNumber }) => ({
        url: `/api/device/v2/start?deviceFingerPrint=${getClientId()}`,
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          station_number: stationNumber,
        }),
      }),
      keepUnusedDataFor: 20,
    }),
    generateCheckoutLinkKwt: builder.query({
      query: ({ stationNumber, kwt }) =>
        `/api/order/generateCheckoutLinkKwt?station_number=${stationNumber}&kwt=${Number(
          kwt,
        )}&deviceFingerPrint=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getBloodyLitersSaved: builder.query({
      query: () => `api/device/v2/getBloodyLitersSaved`,
      keepUnusedDataFor: 1,
    }),
    getNearestStation: builder.query({
      query: ({ lat, lon, top, distanceM }) =>
        `api/device/v2/station/nearest?lat=${lat}&lon=${lon}&top=${top}&distance_m=${distanceM}&fingerPrint=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getStationStatusAll: builder.query({
      query: (fingerPrint) =>
        `api/device/v2/station/all?deviceFingerPrint=${fingerPrint}`,
      keepUnusedDataFor: 1,
    }),
    getDeviceStation: builder.query({
      query: ({ stationNumber, lat, lon }) =>
        `/api/device/v2/station?station_number=${stationNumber}&lat=${lat}&lon=${lon}&deviceFingerPrint=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getStationStatus: builder.query({
      query: ({ stationNumber }) =>
        `/api/device/v2/station/status?station_number=${stationNumber}&deviceFingerPrint=${getClientId()}`,
      keepUnusedDataFor: 1,
    }),
    getUserMe: builder.query({
      query: ({ userToken }) => ({
        url: `/user/me?deviceFingerPrint=${getClientId()}`,
        headers: {
          "X-Auth-Token": userToken,
        },
      }),
    }),
    getB2bStations: builder.query({
      query: ({ userToken }) => ({
        url: `/b2b/stations?deviceFingerPrint=${getClientId()}`,
        headers: {
          "X-Auth-Token": userToken,
        },
      }),
      transformResponse: (response) => ({
        stations: response
          ?.map((el) => el.number)
          .sort((a, b) => a - b)
          .map((n) => {
            return { label: n, value: n };
          }),
      }),
    }),
    getB2bReport: builder.query({
      query: ({ userToken, months, station }) => ({
        url: `/b2b/getReport?deviceFingerPrint=${getClientId()}`,
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Auth-Token": userToken,
        },
        body: JSON.stringify({
          station_numbers: station,
          "months_yyyy-mm": months,
        }),
      }),
    }),
    userLogout: builder.query({
      query: ({ userToken }) => ({
        url: `/user/logout?deviceFingerPrint=${getClientId()}`,
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Auth-Token": userToken,
        },
      }),
    }),
  }),
});

export const {
  useGetNearestStationQuery,
  useGetBloodyLitersSavedQuery,
  useGetStationStatusAllQuery,
  useGetDeviceStationQuery,
  useGetStationStatusQuery,
  useGetUserMeQuery,
  useGetB2bStationsQuery,
  useGetB2bReportQuery,
} = api;
