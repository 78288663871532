import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FullInfo from "../fullInfo/FullInfo";
import {
  PowerMetricsColor,
  VoltageBtn,
} from "../../../components/globalStyles";
import ChargingProgress from "../../../components/chargingProgress/ChargingProgress";
import Timer from "../../../components/timer/Timer";
import { LoadingSm } from "../../../components/loaders/localLoading/LocalLoading";
import ModalContacts from "../../contactsPage/modal/ModalContacts";
import { VLAD_PHONE_NUM, MAX_PHONE_NUM } from "../../contactsPage/ContactsPage";

export default function OnlineStation({
  carKwtKmRatio,
  kWtCharged,
  voltage,
  kWtPower,
  chargeStatus,
  onlineStatus,
  timer,
  isZero,
  lastJob,
  amountMoney,
  type,
}) {
  const [over, setOver] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  function callToSupport() {
    if (!onlineStatus) {
      setIsOpen(true);
    }
  }

  return (
    <div id="onlineStationBox">
      <Row
        id="onlineStationKmMetricsBox"
        className="justify-content-center infoContainer"
      >
        <Col id="onlineStationKmChargedBox" xs lg={6} className="text-center">
          <PowerMetricsColor
            id="onlineStationKmChargedTitle"
            className="mb-0 textTitle"
          >
            {t("charging")}
          </PowerMetricsColor>
          {/* charged  km */}
          <p id="onlineStationKmChargedInfo" className="textInfo text">
            {isZero ? 0 : ((kWtCharged * 1000) / carKwtKmRatio).toFixed(2)}
            {t("km")}
            {/* {type !== "SECONDS" && (
              <span
                id="onlineStationKmChargedInfo"
                style={{ fontSize: "14px" }}
              >
                {amountMoney}
                {t("currency")}
              </span>
            )} */}
          </p>
        </Col>
        <Col id="onlineStationKmPowerBox" xs lg={6} className="text-center">
          <PowerMetricsColor
            id="onlineStationKmPowerTitle"
            className="mb-0 textTitle"
          >
            {t("power")}
          </PowerMetricsColor>
          {/* power km/h */}
          <p id="onlineStationKmPowerInfo" className="textInfo text">
            {onlineStatus === false ? (
              <>{t("onlineStatus")}</>
            ) : (
              <>
                {((kWtPower * 1000) / carKwtKmRatio).toFixed(0)} {t("powerKm")}
              </>
            )}
          </p>
        </Col>
      </Row>
      <ChargingProgress
        status={lastJob}
        online={onlineStatus}
        widthContainer={"120px"}
        marginContainer={"0 auto"}
        widthDot={"20px"}
        heightDot={`20px`}
      />
      {timer !== null ? (
        <Timer
          hours={Number(timer[0])}
          minutes={Number(timer[1])}
          seconds={Number(timer[2])}
          fontSize={"calc(1rem + 1vw)"}
          margin={"0"}
          over={over}
          setOver={setOver}
          onlineStatus={onlineStatus}
        />
      ) : (
        <LoadingSm />
      )}
      <Row id="onlineStationVoltageBox" className="justify-content-center mt-2">
        <VoltageBtn
          id="onlineStationVoltageBtn"
          xs="auto"
          className="text-center activeBtnVoltage"
        >
          <PowerMetricsColor
            id="onlineStationVoltageTitle"
            className="mb-0 textTitle voltTitle"
          >
            {t("voltage")}
          </PowerMetricsColor>
          {/* voltage */}
          <p id="onlineStationVoltageInfo" className="voltTitle textInfo text">
            {onlineStatus === false ? (
              <>{t("onlineStatus")}</>
            ) : (
              <>
                {voltage} {t("v")}
              </>
            )}
          </p>
        </VoltageBtn>
      </Row>

      <FullInfo
        lastJob={lastJob}
        kWtPower={kWtPower}
        chargeStatus={chargeStatus}
        onlineStatus={onlineStatus}
      />

      {!onlineStatus && (
        <button
          onClick={callToSupport}
          className="mainBtn buttonForOfflineStation"
        >
          <span className="mainBtnText">{t("callUs")}</span>
        </button>
      )}

      <ModalContacts
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
        VLAD_PHONE_NUM={VLAD_PHONE_NUM}
        MAX_PHONE_NUM={MAX_PHONE_NUM}
      />
    </div>
  );
}
