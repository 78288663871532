import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useGetDeviceStationQuery } from "../../../redux/api";
import { getGeolocationWithPermission } from "../../../hookServices/getGeolocationWithPermission";

export default function StationNumberLayout({ fingerPrint }) {
  const [searchParams] = useSearchParams();
  let stationNumber = searchParams.get("station");
  // eslint-disable-next-line no-unused-vars
  const [errorLocation, setErrorLocation] = useState(false);

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);

  function handleGeolocationSuccess(position) {
    const latitude = position?.coords?.latitude;
    const longitude = position?.coords?.longitude;
    setLongitude(longitude);
    setLatitude(latitude);
  }
  function handleGeolocationError(error) {
    setErrorLocation(true);
  }
  function getIfError() {
    setErrorLocation(true);
  }

  useEffect(() => {
    getGeolocationWithPermission(
      getIfError,
      handleGeolocationError,
      handleGeolocationSuccess,
    );
    // eslint-disable-next-line
  }, []);

  const {
    data: station,
    isFetching: isFetchingStation,
    isLoading: isLoadingStation,
    isError: isErrorStation,
  } = useGetDeviceStationQuery({
    stationNumber,
    lat: latitude ? latitude : "",
    lon: longitude ? longitude : "",
    fingerPrint,
  });

  const openFrom = station?.openFrom.slice(0, 5);
  const closedFrom = station?.closedFrom.slice(0, 5);
  const isTwentyFourHour =
    station?.openFrom === "00:00:00" && station?.closedFrom === "00:00:00";

  return (
    <Outlet
      context={{
        station,
        stationNumber,
        openFrom,
        closedFrom,
        isTwentyFourHour,
        isFetchingStation,
        isLoadingStation,
        isErrorStation,
      }}
    />
  );
}
