import { useEffect, useState } from "react";
import styles from "./StationCard.module.css";
import { Trans, useTranslation } from "react-i18next";
import {
  CardLink,
  HomeCard,
  ModalTextColorLink,
} from "../../components/globalStyles";
import Timer from "../../components/timer/Timer";
import StationNumber from "../../components/stationNumber/StationNumber";
import ChargingProgress from "../../components/chargingProgress/ChargingProgress";
import { LoadingTime } from "../../components/loaders/localLoading/LocalLoading";
import { DistanceKm } from "../../components/DistanceKm";
import { getDate } from "../../utils/utils";

function StationCardStatus({
  number,
  isFetching,
  online,
  job,
  t,
  openFrom,
  timer,
  over,
  setOver,
}) {
  const timerActive = online && (job?.state === "WAITING" || job !== null);
  const offline = !online;
  const done = job?.state === "DONE" || job === null;
  const inProgress = job?.state === "IN_PROGRESS";
  const waiting = job?.state === "WAITING";

  return (
    <div id={`stationCardStatus${number}Box`} className={styles.status}>
      {isFetching ? (
        <LoadingTime />
      ) : (
        <>
          {timerActive && waiting && !over && (
            <>
              {timer && (
                <Timer
                  hours={Number(timer[0])}
                  minutes={Number(timer[1])}
                  seconds={Number(timer[2])}
                  fontSize={"25px"}
                  margin={"20px 0 0 0"}
                  over={over}
                  setOver={setOver}
                />
              )}
              <Trans i18nKey={"startAt"}>Start at {{ openFrom }}</Trans>
            </>
          )}
          {waiting && offline && (
            <>
              <p
                id={`stationCardStatus${number}Offline`}
                className={styles.readyCharge}
                style={{ fontSize: "25px" }}
              >
                {t("stationOffline")}
              </p>
              <Trans i18nKey={"startAt"}>Start at {{ openFrom }}</Trans>
            </>
          )}
          {done && offline && (
            <p
              id={`stationCardStatus${number}Offline`}
              className={styles.readyCharge}
              style={{ fontSize: "25px" }}
            >
              {t("stationOffline")}
            </p>
          )}
          {inProgress && offline && (
            <>
              <p
                id={`stationCardStatus${number}Offline`}
                className={styles.readyCharge}
                style={{ fontSize: "25px" }}
              >
                {t("stationOffline")}
              </p>
              <ChargingProgress
                status={job}
                online={online}
                widthContainer={"80px"}
                marginContainer={"0"}
                widthDot={"15px"}
                heightDot={`15px`}
              />
            </>
          )}
          {inProgress && !offline && !over && (
            <>
              {timer && (
                <Timer
                  hours={Number(timer[0])}
                  minutes={Number(timer[1])}
                  seconds={Number(timer[2])}
                  fontSize={"25px"}
                  margin={"20px 0 0 0"}
                  over={over}
                  setOver={setOver}
                />
              )}

              <ChargingProgress
                status={job}
                online={online}
                widthContainer={"80px"}
                marginContainer={"0"}
                widthDot={"15px"}
                heightDot={`15px`}
              />
            </>
          )}
          {(done || over) && !offline && (
            <p
              id={`stationCardReadyForUse${number}Title`}
              className={styles.readyCharge}
            >
              {t("readyForUse")}
            </p>
          )}
        </>
      )}
    </div>
  );
}

export const StationCard = ({ station, isFetching }) => {
  const {
    job,
    costPerKwt,
    number,
    online,
    latitude,
    longitude,
    distanceMeters,
    plugshareLink,
  } = station;

  const [over, setOver] = useState(false);
  const [timer, setTimer] = useState(null);

  const isTwentyFourHour =
    station?.openFrom === "00:00:00" && station?.closedFrom === "00:00:00";
  const openFrom = station?.openFrom.slice(0, 5);
  const closedFrom = station?.closedFrom.slice(0, 5);

  const { t } = useTranslation();

  useEffect(() => {
    if (job !== null) {
      if (job.state === "WAITING") {
        setTimer(
          new Date(job?.leftTimeToStartS * 1000)
            .toISOString()
            .slice(11, 19)
            .match(/\d+/g),
        );
      }
      if (job.state === "IN_PROGRESS") {
        setTimer(getDate(job.leftS));
      }
    }
    // eslint-disable-next-line
  }, [job, job?.state]);

  return (
    <CardLink
      id={`stationCard${number}Component`}
      className={styles.linkToStation}
      to={
        job?.state === "IN_PROGRESS" || job?.state === "WAITING"
          ? `/charging?station=${number}`
          : `/start?station=${number}`
      }
    >
      <HomeCard id={`stationCard${number}Box`} className={styles.container}>
        <div
          id={`stationCardStationNumber${number}Box`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div>
            <StationNumber
              station={number}
              costPerKwt={costPerKwt}
              justifyContent="justify-content-start"
            />
            {!isTwentyFourHour && (
              <p style={{ marginBottom: "5px" }} className={styles.costText}>
                {openFrom} — {closedFrom}
              </p>
            )}
          </div>

          <div style={{ textAlign: "right" }}>
            {latitude && longitude && (
              <div className="d-flex justify-content-end">
                <DistanceKm
                  distanceMeters={distanceMeters}
                  latitude={latitude}
                  longitude={longitude}
                />
              </div>
            )}
            {plugshareLink && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "5px",
                }}
              >
                <ModalTextColorLink
                  href={plugshareLink}
                  onClick={(e) => e.stopPropagation()}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      padding: "7px",
                      backgroundColor: "#d2b8d4",
                      color: "#FAFAFA",
                      fontWeight: "600",
                      width: "90px",
                      height: "35px",
                      fontSize: "14px",
                      borderRadius: "11px",
                    }}
                  >
                    <p className="mb-0 align-self-center text-center">
                      PlugShare
                    </p>
                  </div>
                </ModalTextColorLink>
              </div>
            )}
          </div>
        </div>
        <StationCardStatus
          number={number}
          isFetching={isFetching}
          online={online}
          job={job}
          t={t}
          openFrom={openFrom}
          timer={timer}
          over={over}
          setOver={setOver}
        />
      </HomeCard>
    </CardLink>
  );
};
