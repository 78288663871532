import ReactPlayer from "react-player";
import { Row } from "react-bootstrap";

export function WebCamera({ stationNumber, webcamUrl }) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const WIDTH = window.innerWidth;

  return (
    <>
      <Row id="startPageMainImgBox" className="justify-content-center mt-4">
        <ReactPlayer
          url={webcamUrl}
          style={{
            maxHeight: "480px",
            maxWidth: "640px",
            paddingBottom: "50px",
          }}
          width={`${WIDTH}`}
          height={"100%"}
          controls
          playsinline
          playing
          muted
          config={{
            forceHLS: !isSafari,
            forceVideo: true,
            hlsVersion: "0.12.4",
            attributes: {
              disablePictureInPicture: true,
            },
          }}
        />
      </Row>
    </>
  );
}
