import React from 'react'
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';
import "./mainBtn.css";
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function BtnStartAgain({stationNumber}) {
    const {t} = useTranslation();

    let finishBtn = "mainBtn";
    let startBtnText = "mainBtnText";

  return (
    <Row
    id="offlineStationStartAgainBtn"
    as={Link}
    to={`/start?station=${stationNumber}`}
    onClick={() => {
      ReactGA.event({
        category: "startNew",
        action: "start again",
      });
    }}
    className={`${finishBtn}`}
>
  <p id="offlineStationStartAgainTitle"
     className={`${startBtnText}`}>
    {t("startNew")}
  </p>
</Row>
  )
}
