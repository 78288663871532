import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OfferLinksColor } from "../../components/globalStyles";
import styles from "./TermsPage.module.css";
import structureDocument from "./documentSctructure";

export default function TermsPage() {
  const { t } = useTranslation();
  const { body, introduction } = structureDocument;

  const Header = ({ title, id, href }) => (
    <p key={id + "header"}>
      <OfferLinksColor className={styles.offerLink} href={href}>
        <strong>{id}. </strong>
        <strong>{title}</strong>
      </OfferLinksColor>
    </p>
  );

  const IntroductionText = ({ text, index }) => (
    <p key={index + "introduction"}>{text}</p>
  );

  const SubTexts = ({ subTexts }) =>
    subTexts.map(({ id, subText }) => (
      <p key={id + "subText"}>
        <span>{id}</span>
        <br />
        <span>{subText}</span>
      </p>
    ));

  const Text = ({ id, text, subTexts }) => (
    <div key={id + "text"}>
      <p>
        <span>{id}</span>
        <br />
        <span>{text}</span>
      </p>
      {subTexts && <SubTexts subTexts={subTexts} />}
    </div>
  );

  const BodyText = ({ title, id, texts, href }) => {
    const sliceHref = href.slice(1);
    return (
      <div key={id + "bodyText"}>
        <p id={sliceHref}>
          <strong>
            <u>
              {id}. {title}
            </u>
          </strong>
        </p>
        {texts.map((textProps) => (
          <Text key={textProps.id + "texts"} {...textProps} />
        ))}
      </div>
    );
  };

  return (
    <Container id="contractPage">
      <h1 id="contractPageTitle" className={styles.title}>
        {t("offerTitle")}
      </h1>
      <div id="contractPageContainer" className={styles.contractsBox}>
        <div id="contractPageBox" className={styles.textContainer}>
          <p className={styles.contentTitle}>
            <strong>{t("contents")}</strong>
          </p>
          <div className={styles.headerLinks}>
            {body.map((headerProps) => (
              <Header key={headerProps.id} {...headerProps} />
            ))}
          </div>
          <div className={styles.introductionText}>
            {introduction.map((text, index) => (
              <IntroductionText key={index} text={text} />
            ))}
          </div>

          {body.map((bodyProps) => (
            <BodyText key={bodyProps.id} {...bodyProps} />
          ))}
        </div>
      </div>
    </Container>
  );
}
