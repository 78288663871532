import React from "react";
import "./Spinner.css";
import {IoEarthOutline} from "react-icons/io5";

export default function Spinner() {
  return (
      <div id="spinnerBox" className="loadAnimation">
        <IoEarthOutline id="spinnerAnimation" className="animation"/>
      </div>
  );
}
