// noinspection SpellCheckingInspection

import { useEffect, useState } from "react";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import "./layout.css";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar } from "react-bootstrap";
import { ThemeProvider } from "styled-components";
import {
  Footer,
  FooterLink,
  GlobalStyles,
  LinksColor,
  NavBar,
  NavLink,
  Translate,
} from "./globalStyles";
import { lightTheme, darkTheme } from "./darkTheme/Theme";
import { useLocalStorage } from "../hookServices/useLocalStorage";
import ReactGA from "react-ga";
import logo from "../assets/powersource_logo_hirez.png"
import { SVGNightMode } from "./svgComponents/SVGNightMode";
import { SVGLightMode } from "./svgComponents/SVGLightMode";
import { api } from "../redux/api";

export default function Layout() {
  const [open, setOpen] = useState(null);

  let toggleStatus = !open ? "toggle-icon" : "open toggle-icon ";

  const closeMenu = () => {
    setOpen(!open);
  };

  const lngs = {
    ua: { nativeName: "Укр" },
    en: { nativeName: "Eng" },
  };

  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  let stationNumber = searchParams.get("station");

  const { data: stationStatus } =
    api?.endpoints?.getStationStatus?.useQueryState({
      stationNumber,
    });

  // eslint-disable-next-line
  const [togglerStatus, setTogglerStatus] = useLocalStorage(
    "themeTogglerStatus",
    false,
  );
  const [theme, setTheme] = useLocalStorage("themeMode", "light");

  const darkModeSetter = () => {
    setTheme("dark");
  };
  const lightModeSetter = () => {
    setTheme("light");
  };

  const themeToggler = () => {
    setTogglerStatus(true);
    if (theme === "light") {
      darkModeSetter();
    } else {
      lightModeSetter();
    }
  };

  useEffect(() => {
    if (togglerStatus === false) {
      if (stationStatus?.uiNightMode === false) {
        lightModeSetter();
      } else if (stationStatus?.uiNightMode === true) {
        darkModeSetter();
      }
    }
    // eslint-disable-next-line
  }, [stationStatus?.uiNightMode]);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <>
        <GlobalStyles />
        <Container
          id="layout"
          fluid
          style={{ display: "flex", height: "100vh" }}
          className="justify-content-center ml-0 pl-0 mr-0 pr-0"
        >
          <NavBar
            id="layoutNavBar"
            className="fixed-top justify-content-between align-items-center shadow-sm"
            expand="lg"
            collapseOnSelect
          >
            <LinksColor
              id="layoutLogoLink"
              onClick={() => {
                ReactGA.event({
                  category: "logo",
                  action: "landing from logo click",
                });
              }}
              to={`/?station=${stationNumber}`}
              className="flex-row align-items-center"
            >
              <div id="layoutLogoImgBox" className="logoContainer">
								<img alt="logo" src={logo} width="160px" height="20px"/>
							</div>
            </LinksColor>
            <Navbar.Toggle
              id="layoutBurgerBtn"
              onClick={closeMenu}
              bsPrefix={`${toggleStatus}`}
            />
            <Navbar.Collapse id="layoutHeaderCollapsedMenu">
              <Nav
                id="layoutHeaderMenu"
                className="ml-auto mt-auto align-items-center"
              >
                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: "none" }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: "home",
                        action: "landing from burger",
                      });
                    }}
                    eventKey="1"
                    as={Link}
                    to={
                      stationNumber === null
                        ? "/"
                        : `/?station=${stationNumber}`
                    }
                  >
                    <NavLink>{t("landingLink")}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                {stationNumber !== "null" && stationNumber !== null && (
                  <Nav.Item>
                    <Nav.Link
                      style={{ textDecoration: "none" }}
                      className="nav-link links"
                      onClick={() => {
                        closeMenu();
                        ReactGA.event({
                          category: "charge",
                          action: "start or charging page",
                        });
                      }}
                      eventKey="2"
                      as={Link}
                      to={`/start?station=${stationNumber}`}
                    >
                      <NavLink>{t("chargeLink")}</NavLink>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item>
                  <Nav.Link
                    className="nav-link links"
                    onClick={() => {
                      ReactGA.event({
                        category: "contacts",
                        action: "contacts page",
                      });
                    }}
                    eventKey="3"
                    as={Link}
                    to={`/contacts?station=${stationNumber}`}
                  >
                    <NavLink>{t("contacts")}</NavLink>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: "none" }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: "offer",
                        action: "offer page",
                      });
                    }}
                    eventKey="4"
                    as={Link}
                    to={`/contract`}
                  >
                    <NavLink>{t("offer")}</NavLink>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    style={{ textDecoration: "none" }}
                    className="nav-link links"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: "b2b",
                        action: "b2b page",
                      });
                    }}
                    eventKey="5"
                    as={Link}
                    to={`/b2b?station=${stationNumber}`}
                  >
                    <NavLink>{t("b2bHeader")}</NavLink>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item id="layoutNavBarChangeLangBox" className="ml-3 mr-3">
                  <Nav.Link
                    id="layoutNavBarChangeLangNavLink"
                    className="text-decoration-none"
                    eventKey="6"
                    onClick={() => {
                      closeMenu();
                      ReactGA.event({
                        category: "lang",
                        action: "change language",
                      });
                    }}
                  >
                    {Object.keys(lngs).map((lng) => (
                      <Translate
                        id={`layoutNavBar${lngs[lng].nativeName}Btn`}
                        className="ml-1 btnLang"
                        key={lng}
                        style={{
                          fontWeight:
                            i18n.resolvedLanguage === lng ? "bold" : "normal",
                        }}
                        type="submit"
                        onClick={() => i18n.changeLanguage(lng)}
                      >
                        {lngs[lng].nativeName}
                      </Translate>
                    ))}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item id="layoutNavBarChangeThemeBox">
                  <Nav.Link
                    id="layoutNavBarChangeThemeNavLink"
                    eventKey="7"
                    onClick={closeMenu}
                  >
                    <button
                      id="layoutNavBarChangeThemeBtn"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        marginTop: "2px",
                      }}
                      onClick={() => {
                        themeToggler();
                        ReactGA.event({
                          category: "theme",
                          action: "change theme",
                        });
                      }}
                    >
                      {theme === "light" ? <SVGNightMode /> : <SVGLightMode />}
                    </button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </NavBar>

          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <Outlet />
          </div>

          <Footer id="layoutFooter" className="footerBox fixed-bottom">
            <p id="layoutFooterText" className="footerText">
              ©2023 {t("footer.part1")}. {t("footer.part2")}{" "}
              <FooterLink
                id="layoutFooterLink"
                className="footerLink"
                href="https://www.facebook.com/zeBoosterLab/"
                onClick={() => {
                  ReactGA.event({
                    category: "zeBosterLab",
                    action: "facebook page",
                  });
                }}
              >
                ZE Booster Lab
              </FooterLink>{" "}
              {t("footer.part3")}
            </p>
          </Footer>
        </Container>
      </>
    </ThemeProvider>
  );
}
