import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from '@reduxjs/toolkit/query'
import {api} from "./api";
import { webCameraApi } from "../components/webCamera/webCameraApi";

export const setupStore = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [webCameraApi.reducerPath]: webCameraApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([api.middleware, webCameraApi.middleware]),
});

setupListeners(setupStore.dispatch)
