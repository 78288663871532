/* eslint-disable */
import { Col, Container, Row } from "react-bootstrap";
import "../../components/buttons/mainBtn.css";
import { useTranslation } from "react-i18next";
import ErrorPage from "../errorPage/ErrorPage";
import Spinner from "../../components/loaders/globalSpinner/Spinner";
import { StationCard } from "./StationCard";
import { api, useGetBloodyLitersSavedQuery } from "../../redux/api";
import { useEffect, useState } from "react";
import { getGeolocationWithPermission } from "../../hookServices/getGeolocationWithPermission";

export default function HomePage({ fingerPrint }) {
  const { data: bloodyLitters, isLoading: bloodyIsLoading } =
    useGetBloodyLitersSavedQuery();

  const { t } = useTranslation();

  const [getStationStatusAll] = api.useLazyGetStationStatusAllQuery();
  const [getStationStatusNearest] = api.useLazyGetNearestStationQuery();

  const [isLocation, setIsLocation] = useState(false);
  const [stationsArr, setStationsArr] = useState();

  const [allStationsStatus, setAllStationStatus] = useState(null);
  const [errorAllStationsStatus, setErrorAllStationsStatus] = useState();
  const [setLoadingAllStationsStatus] = useState();

  const [nearestStationsStatus, setNearestStationStatus] = useState(null);
  const [errorNearestStationStatus, setErrorNearestStationStatus] = useState();
  const [loadingNearestStationStatus, setLoadingNearestStationStatus] =
    useState();

  const getAllStations = async () => {
    if (!allStationsStatus) {
      const { data, isSuccess, isLoading, isError, error } =
        await getStationStatusAll(fingerPrint);
      if (isLoading && !isSuccess && !isError) {
        setLoadingAllStationsStatus(true);
        setLoadingNearestStationStatus(false);
      }
      if (isSuccess && !isError) {
        setAllStationStatus(data);
        setNearestStationStatus(null);
      }
      if (!isSuccess && isError) {
        setErrorAllStationsStatus(error);
        setErrorNearestStationStatus(undefined);
      }
    }
  };
  const getNearestStation = async (position) => {
    const { data, isSuccess, isLoading, isError, error } =
      await getStationStatusNearest({
        lat: position.coords.latitude,
        lon: position.coords.longitude,
        top: "",
        distanceM: "",
        fingerPrint,
      });
    if (isLoading && !isSuccess && !isError) {
      setLoadingAllStationsStatus(false);
      setLoadingNearestStationStatus(true);
    }
    if (isSuccess && !isError) {
      setAllStationStatus(null);
      setNearestStationStatus(data);
    }
    if (!isSuccess && isError) {
      setErrorAllStationsStatus(undefined);
      setErrorNearestStationStatus(error);
    }
  };

  function handleGeolocationSuccess(position) {
    getNearestStation(position);
  }

  function handleGeolocationError(error) {
    getAllStations();
  }

  useEffect(() => {
    getGeolocationWithPermission(
      getAllStations,
      handleGeolocationError,
      handleGeolocationSuccess,
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (allStationsStatus && !nearestStationsStatus) {
      setStationsArr(allStationsStatus);
    }
    if (!allStationsStatus && nearestStationsStatus) {
      setStationsArr(nearestStationsStatus);
    }
  }, [allStationsStatus, nearestStationsStatus]);

  if (
    (errorNearestStationStatus && errorNearestStationStatus !== 400) ||
    errorAllStationsStatus
  ) {
    return (
      <ErrorPage
        errorHeader={t("errorDevHeader")}
        errorBody={t("errorDevBody")}
      />
    );
  }
  if (!stationsArr) {
    return <Spinner />;
  }

  return (
    <Container id="homePage" style={{ overflowX: "hidden" }}>
      <div
        style={{
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <Row
          style={{ backgroundColor: "transparent" }}
          className="mainBtn mb-3"
        >
          {!bloodyIsLoading && (
            <p
              style={{
                color: "inherit",
                borderBottom: "1px solid #dbdbdb",
                fontSize: "18px",
              }}
              className="mainBtnText"
            >
              {t("getBloodyLitersSaved")} {bloodyLitters}
            </p>
          )}
        </Row>
        <>
          {!isLocation && (
            <>
              {!nearestStationsStatus && (
                <Row
                  id="getNearestStationsStatus"
                  className="mainBtn"
                  onClick={() => alert(`${t("locationError")}`)}
                >
                  <p className={"mainBtnText"}>
                    {t("btns.getNearestStationsStatus")}
                  </p>
                </Row>
              )}
            </>
          )}
        </>
      </div>

      <Row id="homePageContainer" className="justify-content-center mb-5">
        {stationsArr?.length > 0 ? (
          stationsArr?.map((station) => {
            return (
              <Col
                id={`homePageCards${station?.number}Box`}
                lg={4}
                md={6}
                key={Math.random()}
              >
                <StationCard
                  station={station}
                  isFetching={loadingNearestStationStatus}
                />
              </Col>
            );
          })
        ) : (
          <Row
            style={{ backgroundColor: "transparent" }}
            className="mainBtn pr-2 pl-2"
          >
            <p
              style={{
                color: "inherit",
                textAlign: "center",
                fontSize: "22px",
              }}
              className="mainBtnText mb-3"
            >
              {t("noNearestStations")}
            </p>
          </Row>
        )}
      </Row>
    </Container>
  );
}
