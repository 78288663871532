import React, { useState, useEffect, useMemo } from "react";
import modules from "./Slider.module.css";
import { useTranslation } from "react-i18next";
import { SliderContainer } from "../../../components/globalStyles";
import { CustomSlider, CustomBox } from "./CustomizationSlider";

export default function DiscreteSlider({ setKwt, checkTimeAndOpenLink, kwt }) {
  const [valueEnergy, setValueEnergy] = useState(kwt);

  const SLIDER_STEP = 5;
  const SLIDER_MIN_VALUE = 5;
  const SLIDER_MAX_VALUE = 100;

  const { t } = useTranslation();

  const updateValueKWt = (event, value) => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(50);
    }
    setValueEnergy(Number(value));
    setKwt(Number(value));
  };

  useEffect(() => {
    const getLastKwt = Number(localStorage.getItem("lastKwt"));
    if (getLastKwt && !isNaN(getLastKwt)) {
      setValueEnergy(getLastKwt);
    } else {
      setKwt(Number(valueEnergy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setKwt]);
  const memoTextSlider = useMemo(() => {
    return (
      <p className={modules["stationTextSlider"]}>
				{t("btnPayFor")} {valueEnergy} {t("btns.startKwt")}
      </p>
    );
  }, [valueEnergy, t]);
  return (
    <SliderContainer>
      <div
        className={modules["stationBlockSlider"]}
        onClick={checkTimeAndOpenLink}
      >
        {memoTextSlider}
      </div>
      <CustomBox>
        <CustomSlider
          aria-label="Energy"
          value={valueEnergy}
          onChange={updateValueKWt}
          step={SLIDER_STEP}
          min={SLIDER_MIN_VALUE}
          max={SLIDER_MAX_VALUE}
        />
      </CustomBox>
    </SliderContainer>
  );
}
