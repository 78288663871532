import React from "react";
import "../../components/buttons/mainBtn.css";
import "./startPage.css";
import { Trans, useTranslation } from "react-i18next";
import { Row } from "react-bootstrap";
import Modal from "../../components/modal/Modal";
import { Link } from "react-router-dom";
import { saveClientId } from "../../hookServices/clientId";

export default function FirstDelayedModal({
  stationNumber,
  kwt,
  setModalOpen,
  modalOpen,
  nextModalOpen,
  isTwentyFourHour,
  openPaymentLin,
  setError,
}) {
  const { t } = useTranslation();

  function storeClientIdAndSwitchModal() {
    localStorage.setItem("lastKwt", String(kwt));
    saveClientId();
    setModalOpen(false);
    if (!isTwentyFourHour) {
      nextModalOpen(true);
    } else {
      openPaymentLin(stationNumber, kwt, setError);
    }
  }
  return (
    <Modal setModalOpen={setModalOpen} modalOpen={modalOpen}>
      <h1 className="delayedStartWarning">
        <Trans i18nKey="firstVisitStart">
          We return money if you charge less {{ kwt }} than paid
        </Trans>
      </h1>
      <Row
        onClick={() => storeClientIdAndSwitchModal()}
        as={Link}
        to={`/start?station=${stationNumber}`}
        className="mainBtn mb-1 mt-4"
      >
        <p className="mainBtnText ">{t("btnPay")}</p>
      </Row>
    </Modal>
  );
}
