/* eslint-disable */
import React, { useState } from "react";
import "./startPage.css";
import "../../components/buttons/mainBtn.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import ErrorPage from "../errorPage/ErrorPage";
import { Container, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { WebCamera } from "../../components/webCamera/WebCamera";
import StationNumber from "../../components/stationNumber/StationNumber";
import { startCharging } from "./startCharging";
import ModalDelayedStart from "./ModalDelayedStart";
import FirstDelayedModal from "./FirstDelayedModal";
import { useEffect } from "react";
import { api } from "../../redux/api";
import { useDispatch } from "react-redux";
import { getClientId, saveClientId } from "../../hookServices/clientId";
import DiscreteSlider from "./slider/Slider";
import { isWorkingTime } from "../../utils/utils";
import ModalContacts from "../contactsPage/modal/ModalContacts";
import { VLAD_PHONE_NUM, MAX_PHONE_NUM } from "../contactsPage/ContactsPage";

export default function StartPage() {
	const INITIAL_SLIDER_KWH = 44;
	const FULL_CHARGE_KWH = 66;

  const [modalOpen, setModalOpen] = useState(false);
  const [kwt, setKwt] = useState(INITIAL_SLIDER_KWH);
  const [error, setError] = useState(null);
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [startFreeStatus, setStartFreeStatus] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const navigate = useNavigate();

  const { station, stationNumber, openFrom, closedFrom, isTwentyFourHour } =
    useOutletContext();

  useEffect(() => {
    if (startFreeStatus === "success") {
      dispatch(
        api?.util.upsertQueryData("getStationStatus", { stationNumber }, {}),
      );
      navigate(`/charging?station=${stationNumber}`);
    }
  }, [startFreeStatus]);

  useEffect(() => {
    const lastKwt = localStorage.getItem("lastKwt");
    if (lastKwt === null) {
      localStorage.setItem("lastKwt", String(INITIAL_SLIDER_KWH));
    } else {
      setKwt(Number(lastKwt));
    }
  }, []);

  const dispatch = useDispatch();

  const { startChargingFree, openPaymentLink } = startCharging();
  const isWorking = isWorkingTime(
    station,
    openFrom,
    closedFrom,
    isTwentyFourHour,
  );

  let startBtn = "mainBtn";
  let startBtnText = "mainBtnText";

  const { t } = useTranslation();

	function startFree() {
		//todo move this check to startCharging
		if (!getClientId()) {
			saveClientId();
		}
		const status = startChargingFree(stationNumber, getClientId());
		status.then((result) => {
			setStartFreeStatus(result);
		});
	}
	function startFullCharge() {
		// for modal window FirstDelayedModal
		setKwt(FULL_CHARGE_KWH)

		if (!getClientId()) {
			setNoticeModalOpen(true);
			return;
		}

		if (!isWorking) {
			setModalOpen(true);
		} else {
			openPaymentLink(stationNumber, FULL_CHARGE_KWH, setError);
			ReactGA.event({
				category: `start_${FULL_CHARGE_KWH}_kWt`,
				action: `start_${FULL_CHARGE_KWH}_kWt`,
			});
		}
	}
	// todo extract to slider component
  function start(event) {
    try {
      const selectorSliderButton =
        "#startPageBtn220Km44Kwt, #startPageBtn220Km44KwtInfo";

      if (!event.target.closest(selectorSliderButton)) {
        localStorage.setItem("lastKwt", String(kwt));
      } else {
        if (!getClientId()) {
          setKwt(INITIAL_SLIDER_KWH);
          setNoticeModalOpen(true);
          return;
        }
        openPaymentLink(stationNumber, INITIAL_SLIDER_KWH, setError);
        return;
      }
      if (!getClientId()) {
        setNoticeModalOpen(true);
        setKwt(Number(kwt));
        return;
      }

      if (!isWorking) {
        setModalOpen(true);
        setKwt(Number(kwt));
      } else {
        openPaymentLink(stationNumber, kwt, setError);
        ReactGA.event({
          category: `start_${kwt}_kWt`,
          action: `start_${kwt}_kWt`,
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  }

  if (error) {
    return (
      <ErrorPage
        errorHeader={
          <Trans i18nKey="errorOfflineHeader">
            Plug #{{ stationNumber }} is offline :(
          </Trans>
        }
        errorBody={t("errorOfflineBody")}
      />
    );
  }

	return (
    <Container id="startPage" fluid="lg" style={{ overflowX: "hidden" }}>
      <Row id="startPageTitleBox" className="justify-content-center mt-3 mb-4">
        <h1 id="startPageTitle" className="title">
          {t("title")}
        </h1>
      </Row>

      <StationNumber
        justifyContent="justify-content-center"
        station={stationNumber}
        costPerKwt={station?.costPerKwt}
        distanceMeters={station?.distanceMeters}
        latitude={station?.latitude}
        longitude={station?.longitude}
        plugshareLink={station?.plugshareLink}
      />
      {!isTwentyFourHour && (
        <Row
          id="startPageTitleBox"
          className="justify-content-center mt-3 mb-3 pr-1 pl-1"
        >
          <p className="delayedStartTime">
            <Trans i18nKey={"delayedInfo"}>
              Порт працює з {{ openFrom }} до
              {{ closedFrom }}
            </Trans>
          </p>
        </Row>
      )}
      <div id="startPageBtnsBox" className="btnStartContainer">
        {/*todo fix https://esound.youtrack.cloud/youtrack/agiles/87-6/current?issue=220-639*/}
        {/*{isWorking && (*/}
        {/*  <Row*/}
        {/*    id="startPageBtnFreeStart"*/}
        {/*    as={"div"}*/}
        {/*    className={`${startBtn}`}*/}
        {/*    onClick={startFree}*/}
        {/*  >*/}
        {/*    <p className={`${startBtnText}`}>{t("btns.startFree")}</p>*/}
        {/*  </Row>*/}
        {/*)}*/}
				<Row
						id="startPageBtnStartFullCharge"
						as={"div"}
						className={`${startBtn}`}
						onClick={startFullCharge}
				>
					<p className={`${startBtnText}`}>{t("btns.startFullCharge")}</p>
				</Row>
        <Row className="justify-content-center">
          <DiscreteSlider
            setKwt={setKwt}
            checkTimeAndOpenLink={start}
            kwt={kwt}
          />
        </Row>
      </div>
      <WebCamera stationNumber={stationNumber} webcamUrl={station?.webcamUrl} />
      <div className="buttonForCallingUs" onClick={() => setIsOpenModal(true)}>
        <div className="mainBtn d-flex justify-content-center ">
          <span className="mainBtnText">{t("callUs")}</span>
        </div>
      </div>
      {isOpenModal && (
        <ModalContacts
          setModalOpen={setIsOpenModal}
          modalOpen={isOpenModal}
          VLAD_PHONE_NUM={VLAD_PHONE_NUM}
          MAX_PHONE_NUM={MAX_PHONE_NUM}
        />
      )}

      <ModalDelayedStart
        stationNumber={stationNumber}
        openPaymentLink={openPaymentLink}
        setError={setError}
        kwt={kwt}
        openFrom={openFrom}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <FirstDelayedModal
        stationNumber={stationNumber}
        kwt={kwt}
        modalOpen={noticeModalOpen}
        setModalOpen={setNoticeModalOpen}
        nextModalOpen={setModalOpen}
        isTwentyFourHour={isTwentyFourHour}
        openPaymentLin={openPaymentLink}
        setError={setError}
      />
    </Container>
  );
}
