import React, {useEffect, useState} from "react";
import "./chargingProgress.css";
import {percentageCharged} from "./percentageCharges";

export default function ChargingProgress({
  status,
  online,
  widthContainer,
  marginContainer,
  widthDot,
  heightDot
}) {
  const [percent, setPercent] = useState();
  const [rangeError, setRangeError] = useState(false);

  const dotWidth = `${widthDot}`;
  const dotHeight = `${heightDot}`;

  useEffect(() => {
    if (status?.periodS) {
      let result = percentageCharged(status?.periodS,
          status?.leftS, status?.type, setRangeError)
      setPercent(result)
    }
    // when BE will change, rename periodKwt to periodWt
    if (status?.periodKwt) {
      let result = percentageCharged(status?.periodKwt,
          status?.leftWt, status?.type, setRangeError)
      setPercent(result)
    }
  }, [status, percent]);

  let onlineBlinkingStatus = online ? 'dotItemActive' : 'dotItemDefault'

  return (
      <div style={{width: `${widthContainer}`, margin: `${marginContainer}`}}>
        {!rangeError ? (
           <div className="dotsBox">
          {percent <= 25 ? (
              <>
                <div className={`dotItem ${onlineBlinkingStatus}`} style={{
                  width: `${dotWidth}`,
                  height: `${dotHeight}`
                }}></div>
                <div className="dotItem dotItemDefault" style={{
                  width: `${dotWidth}`,
                  height: `${dotHeight}`
                }}></div>
                <div className="dotItem dotItemDefault" style={{
                  width: `${dotWidth}`,
                  height: `${dotHeight}`
                }}></div>
                <div className="dotItem dotItemDefault" style={{
                  width: `${dotWidth}`,
                  height: `${dotHeight}`
                }}></div>
              </>
          ) : (
              <>
                {percent > 25 && percent <= 50 ? (
                    <>
                      <div className="dotItem dotItemCharged" style={{
                        width: `${dotWidth}`,
                        height: `${dotHeight}`
                      }}></div>
                      <div className={`dotItem ${onlineBlinkingStatus}`}
                           style={{
                             width: `${dotWidth}`,
                             height: `${dotHeight}`
                           }}></div>
                      <div className="dotItem dotItemDefault" style={{
                        width: `${dotWidth}`,
                        height: `${dotHeight}`
                      }}></div>
                      <div className="dotItem dotItemDefault" style={{
                        width: `${dotWidth}`,
                        height: `${dotHeight}`
                      }}></div>
                    </>
                ) : (
                    <>
                      {percent > 50 && percent <= 75 ? (
                          <>
                            <div className="dotItem dotItemCharged" style={{
                              width: `${dotWidth}`,
                              height: `${dotHeight}`
                            }}></div>
                            <div className="dotItem dotItemCharged" style={{
                              width: `${dotWidth}`,
                              height: `${dotHeight}`
                            }}></div>
                            <div className={`dotItem ${onlineBlinkingStatus}`}
                                 style={{
                                   width: `${dotWidth}`,
                                   height: `${dotHeight}`
                                 }}></div>
                            <div className="dotItem dotItemDefault" style={{
                              width: `${dotWidth}`,
                              height: `${dotHeight}`
                            }}></div>
                          </>
                      ) : (
                          <>
                            {percent > 75 && percent < 100 ? (
                                <>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                  <div
                                      className={`dotItem ${onlineBlinkingStatus}`}
                                      style={{
                                        width: `${dotWidth}`,
                                        height: `${dotHeight}`
                                      }}></div>
                                </>
                            ) : (
                                <>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                  <div className="dotItem dotItemCharged"
                                       style={{
                                         width: `${dotWidth}`,
                                         height: `${dotHeight}`
                                       }}></div>
                                </>
                            )}
                          </>
                      )}
                    </>
                )}
              </>
          )}
        </div>
      
        ) : null}
       
       </div>
  );
}
