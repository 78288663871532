import ReactGA from "react-ga";
import { api } from "../../redux/api";

export const startCharging = () => {
  const [getPaymentLink] = api.useLazyGenerateCheckoutLinkKwtQuery();
  const [startFree] = api.useLazyDeviceStartQuery();

  const startChargingFree = async (stationNumber) => {
    const { error } = await startFree({ stationNumber });
    ReactGA.event({
      category: "startChargingFree",
      action: "start free",
    });
    if (error) return error?.data?.data;
    return "success";
  };
  const openPaymentLink = async (stationNumber, kwt, setError) => {
    kwt = Number(kwt);

    let win = window.open();
    const { data, isSuccess, error } = await getPaymentLink({
      stationNumber,
      kwt,
    });
    if (error) {
      setError(error.status);
      if (win === null) {
        return;
      }
      win.close();
    }
    if (isSuccess) {
      win.location = `${data?.pageUrl}`;
    }
  };
  return {
    startChargingFree,
    openPaymentLink,
  };
};
