import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "ua",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ua: {
        translation: {
          btns: {
            start: " годин",
            startKwt: " кВт*г",
            startFree: "Спробувати безкоштовно",
						startFullCharge: "Оплатити до повного",
            getAllStationsStatus: "Показати всі станції",
            getNearestStationsStatus: "Станції поруч",
          },
          title: "Заряди 220 кілометрів за ніч",
          station: "Порт  #",
          chargeLink: "Зарядка",
          landingLink: "Головна",
          contacts: "Зв'яжіться з нами",
          offer: "Умови користування",
          wt: "кВт*г",
          powerWt: " кВт",
          currency: " грн",
          v: "В",
          km: " км",
          chargedCongrats: "Вітаємо!",
          chargedkWt: "Заряджено: ",
          startNew: "Ще раз",
          power: "Потужність",
          onlineStatus: "Офлайн",
          powerKm: "км/год",
          charging: "Заряджено",
          voltage: "Вольтаж",
          chargingSpeed: "Швидкість заряду",

          allStationsStatusUnavailable: "Наразі не доступно",
          getBloodyLitersSaved: "Збережено кровавих літрів: ",
          noNearestStations: "Поряд з вами станцій не виявлено",
          getDirection: "Отримати маршрут",
          map: "Карта",
          distanceKm: " км",
          distanceM: "м",
          locationError:
            "Увімкніть доступ до місцезнаходження в налаштуваннях браузера.",
          toHomePage: "На головну",

          login: "Увійти з Google",
          loginTitle: "Вітаємо",
          loginText: "Увійдіть, щоб продовжити",

          logout: "Вийти",

          userInfoName: "Уввійшли як",

          error: "Помилка",
          errorDevHeader: "Помилка серверу",
          errorDevBody: "Ми вже вирішуємо проблему і скоро повернемось :)",
          errorOfflineHeader: "Порт #<1>{{stationNumber}}</1> офлайн :(",
          errorOfflineBody: "Спробуйте, будь ласка, пізніше",
          errorPayHeader: "Оплата недоступна!",
          errorPayBody: "Ми вже вирішуємо проблему і скоро повернемось :)",
          errorStartFailedHeader: "Невдалося стартувати :(",
          errorStartFailedBody: "",
          errorFingerPrintUndefindHeader: "Виявлений блокувальник реклами",
          errorFingerPrintUndefindBody:
            "Будь ласка, вимкніть і спробуйте ще раз",
          errorFingerPrintUnavailableHeader:
            "Безкоштовна зарядка вже використана",
          errorFingerPrintUnavailableBody: "Здійсніть будь ласка оплату :)",

          stationOffline: "Офлайн :(",

          costPerHour: " грн за годину",
          costPerKwt: " грн за кВт*г",

          helpCall: "Зателефонувати",

          readyForUse: "Вільний!",

          calibration: "Відкалібруйте результат!",
          enterYourKm: "Будь ласка, введіть заряджені кілометри",
          sendKm: "Калібрувати",
          calibratedKm: "Ваш результат відкалібровано",
          btnRepeat: "Повторити",

          featureInProgress: "Чекайте в наступному оновленні :)",

          back: "Повернутись",
          close: "Закрити",
          delayedStart: "Зарядка почнеться о <1>{{openFrom}}</1>",
          firstVisitStart:
            "Після закінчення зарядної сесії, невикористаний залишок кВт*г, відразу повертається на карту",
          cancelDelayedStart: "Відмінити",
          delayedInfo: "Працює з <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>",
          leftBeforeStart: "До старту залишилося:",
          startAt: "Почнеться о <1>{{openFrom}}</1>",
          stationCardInfo: "з <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>",

          offerTitle:
            "ПУБЛІЧНИЙ ДОГОВІР  ПРО НАДАННЯ ПОСЛУГ З ЗАРЯДКИ ЕЛЕКТРОТРАНСПОРТУ  (ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ).",
          contents: "Дата публікації: 09.08.2022",
          callUs: "Зателефонувати",
          instagram: "Інстаграм",
          telegram: "Телеграм",
          watsapp: "Ватсап",
          tapCall: "Натисніть, щоб зателефонувати",
          telVlad: "Влад",
          telMax: "Макс",
          notFound: "Сторінка не знайдена",
          btnPay: "Оплатити",
					btnPayFor: "Оплатити",
					overload: {
            waitForLink: "Зачекайте секунду",
            gettingLink: "Отримуємо посилання для оплати",
            successChecked: "Вийшло",
            letsCharge: "Погнали заряджати",
            checking: "перевіряємо навантаження",
            overloadDetected: "Виявлено перевантаження",
            slowdown: "Будь ласка, зменшіть напругу до",
            repeat: "і спробуйте ще раз",
          },
          footer: {
            part1: "Всі права захищені",
            part2: "Розроблено",
            part3: "з ♥ до електромобілів",
          },

          b2bHeader: "Для бізнесу",
          b2bNoStationsHeader: "Ви ще не маєте станцій",
          b2bNoStationsBody: "Зв'яжіться з нами для замовлення",
          months: {
            january: "Січень",
            february: "Лютий",
            march: "Березень",
            april: "Квітень",
            may: "Травень",
            june: "Червень",
            july: "Липень",
            august: "Серпень",
            september: "Вересень",
            october: "Жовтень",
            november: "Листопад",
            december: "Грудень",
          },
        },
      },
      en: {
        translation: {
          btns: {
            start: " hours",
            startKwt: " kWh",
            startFree: "Try for free",
						startFullCharge: "Pay for full charge",
            getAllStationsStatus: "Show all stations",
            getNearestStationsStatus: "Show nearby",
          },
          title: "Charge 140 miles at night",
          station: "Plug  #",
          chargeLink: "Charging",
          landingLink: "Home",
          contacts: "Contact us",
          offer: "Terms and conditions",
          wt: "kW*h",
          powerWt: " kW",
          currency: " UAH",
          v: "V",
          km: " ml",
          chargedCongrats: "Congrats!!!",
          chargedkWt: "Your car has been charged by: ",
          startNew: "Go again",
          power: "Power",
          onlineStatus: "Offline",
          powerKm: "km/h",
          charging: "Charged",
          voltage: "Voltage",
          chargingSpeed: "Charging speed",

          allStationsStatusUnavailable: "Unavailable now",
          getBloodyLitersSaved: "Bloody liters saved: ",
          noNearestStations: "No stations found near you",
          getDirection: "Get direction",
          map: "Map",
          distanceKm: " ml",
          distanceM: "m",
          locationError:
            "Please enable location access in your browser settings.",
          toHomePage: "To Home page",

          login: "Log In with Google",
          loginTitle: "Welcome",
          loginText: "Log in to continue",

          logout: "Log out",

          userInfoName: "Logged in as",

          error: "Error",
          errorDevHeader: "Server error.",
          errorDevBody: "We are reinventing and will be back soon :)",
          errorOfflineHeader: "Plug #<1>{{stationNumber}}</1> is offline :(",
          errorOfflineBody: "Please, try again later",
          errorPayHeader: "Payment is unavailable!",
          errorPayBody: "Sorry! We are reinventing and will be back soon :)",
          errorStartFailedHeader: "Start has been failed :(",
          errorStartFailedBody: "",
          errorFingerPrintUndefindHeader: "Ad block detected",
          errorFingerPrintUndefindBody: "Please, turn it off and try again",
          errorFingerPrintUnavailableHeader: "Free charge is unavailable",
          errorFingerPrintUnavailableBody: "Please pay:)",

          stationOffline: "Offline :(",

          costPerHour: " cad per hour",
          costPerKwt: " cad per kWh",

          helpCall: "Call us",

          readyForUse: "Ready!",

          calibration: "Calibrate result!",
          enterYourKm: "Please, enter your ml",
          sendKm: "Calibrate",
          calibratedKm: "Your result was calibrated successfully",
          btnRepeat: "Try Again",

          featureInProgress: "Coming soon :)",

          back: "Back",
          close: "Close",

          delayedStart: "Charging will begin at <1>{{openFrom}}</1>",
          firstVisitStart:
            "Not used kWh will be refunded after a finish of a charging session.",
          cancelDelayedStart: "Cancel",
          delayedInfo:
            "Works from <1>{{openFrom}}</1> till <1>{{closedFrom}}</1>",
          leftBeforeStart: "Will start in:",
          startAt: "Will start at <1>{{openFrom}}</1>",
          stationCardInfo: "from <1>{{openFrom}}</1> to <1>{{closedFrom}}</1>",

          callUs: "Call Us",
          instagram: "Instagram",
          telegram: "Telegram",
          watsapp: "WatsApp",
          tapCall: "Tap for calling",
          telVlad: "Vlad",
          telMax: "Max",
          notFound: "Page not found",
          btnPay: "Pay",
					btnPayFor: "Pay for",
          overload: {
            waitForLink: "Wait a second",
            gettingLink: "Getting payment link",
            btnPay: "Pay",
            successChecked: "Success",
            letsCharge: "Let`s charge it",
            checking: "is overloaded checking",
            overloadDetected: "Overload",
            slowdown: "Please, slow down to",
            repeat: "and try again",
          },
          footer: {
            part1: "All rights reserved",
            part2: "Made by",
            part3: "with ♥ to Zero Emission Vehicles",
          },

          b2bHeader: "B2B",
          b2bNoStationsHeader: "No stations are available yet",
          b2bNoStationsBody: "Contact us to order",
          months: {
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
          },
        },
      },
    },
  });

export default i18n;
