import React, {useEffect, useState} from 'react'
import "./startPage.css";
import "../../components/buttons/mainBtn.css";
import {Row} from 'react-bootstrap'
import Timer from "../../components/timer/Timer";
import { useTranslation } from 'react-i18next';
import { LoadingSm } from '../../components/loaders/localLoading/LocalLoading';

export default function DelayedStartWaitingPage({leftTimeToStartS}) {
  const [timer, setTimer] = useState()
  const [over, setOver] = useState(false);

  const {t} = useTranslation();

  useEffect(() => {
    setTimer(
        new Date(leftTimeToStartS * 1000)
        .toISOString()
        .slice(11, 19)
        .match(/\d+/g)
    );
    // eslint-disable-next-line
  }, [])

  return (
      <div style={{overflow: 'hidden', padding: '20px'}}>
        <Row className='justify-content-center waititngTitle'>{t("leftBeforeStart")}</Row>
        <Row className='justify-content-center'>
            {timer ? (
                <Timer
              hours={Number(timer[0])}
              minutes={Number(timer[1])}
              seconds={Number(timer[2])}
              fontSize={"calc(1rem + 1vw)"}
              margin={"0"}
              over={over}
              setOver={setOver}
          />
            ) : (
              <LoadingSm />
            )}
          
        </Row>
      </div>
  )
}
