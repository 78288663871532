import React, { useState } from "react";
import "./GetPower.css";
import "../../../components/buttons/mainBtn.css";
import { Col } from "react-bootstrap";
import {
  FinishKmStap,
  FinishKwtStap,
  PowerMetricsColor,
} from "../../../components/globalStyles";
import ModalCalibrate from "./modal/ModalCalibrate";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";
import { ENV } from "../../../environment";
import BtnStartAgain from "../../../components/buttons/BtnStartAgain";

export default function OfflineStation({
  kWtCharged,
  carKwtKmRatio,
  chargeStatus,
  stationNumber,
  amountMoney,
  type,
}) {
  const [modalOpen, setModalOpen] = useState();

  let finishBtn = "mainBtn";
  let startBtnText = "mainBtnText";

  const { t } = useTranslation();

  const openModal = () => {
    ReactGA.event({
      category: "calibration",
      action: "open calibration modal",
    });
    if (ENV === "prod") {
      console.log("close");
    } else {
      setModalOpen(true);
    }
  };

  const styleModalBtn = ENV === "prod" ? "disableModalBtn" : `${finishBtn}`;

  const styleModalBtnText =
    ENV === "prod" ? "disableModalBtnText" : `${startBtnText}`;

  return (
    <div id="offlineStationContainer">
      <Col id="offlineStationBox" xs="auto" lg="auto" className="text-center">
        <PowerMetricsColor
          id="offlineStationCongratsTitle"
          className="finishTitle"
        >
          {t("chargedCongrats")}{" "}
        </PowerMetricsColor>
        <p id="offlineStationChargedText" className="finishText">
          {t("chargedkWt")}
        </p>
        <div id="offlineStationSubBox">
          <FinishKmStap
            id="offlineStationCalibrateModalBtn"
            onClick={openModal}
            className={styleModalBtn}
          >
            <p id="offlineStationKmChargedInfo" className={styleModalBtnText}>
              {Math.round((kWtCharged * 1000) / Math.round(carKwtKmRatio))}
              {t("km")}
              {/* {type !== "SECONDS" && (
                <span style={{ fontSize: "14px" }}>
                  ({amountMoney} {t("currency")})
                </span>
              )} */}
            </p>
          </FinishKmStap>
          <FinishKwtStap
            id="offlineStationKwtChargedInfo"
            style={{ fontSize: "calc(0.9rem + 1.3vw)", margin: "10px 0 5px" }}
          >
            {/* cahrged kWt */}
            {chargeStatus} {t("wt")}
          </FinishKwtStap>{" "}
          <BtnStartAgain stationNumber={stationNumber} />
        </div>
      </Col>

      {ENV !== "prod" && (
        <ModalCalibrate
          stationNumber={stationNumber}
          chargedKm={Math.round(
            (kWtCharged * 1000) / Math.round(carKwtKmRatio)
          )}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
}
