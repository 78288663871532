import React, {useEffect, useState} from "react";
import "./dropDown.css";
import Select, {
    components,
} from "react-select";
import {IoClose} from "react-icons/io5";

const Option = (props) => {
    return (
        <div
            onClick={() => {
                props.selectProps.b2bParamsFunc.addParam(props.data.value)
            }}
        >
            <components.Option {...props} />
        </div>
    );
};

const MultiValueRemove = (props) => {
    const removeValue = () => {
        props.selectProps.b2bParamsFunc.deleteParam(props.data.value)
    };
    return (
        <div
            onTouchStart={() => {
                removeValue();
            }}
            onMouseDown={() => {
                removeValue();
            }}
            style={{alignSelf: "center"}}
        >
            <components.MultiValueRemove {...props}>
                {props.children}
            </components.MultiValueRemove>
        </div>
    );
};

const ClearIndicator = (props) => {
    const {children = <IoClose size={20}/>, clearValue, getValue} = props;
    return (
        <div
            onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            onTouchStartCapture={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
        >
            <div
                onClick={() => {
                    const value = getValue();
                    clearValue();
                    props.selectProps.b2bParamsFunc.clearParams(value[0].value)
                }}
                style={{padding: "0px 5px"}}
            >
                {children}
            </div>
        </div>
    );
};

export default function DropDown({
                                     options,
                                     defaultValue,
                                     b2bParamsFunc
                                 }) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        if (defaultValue?.value?.includes("-")) {
            b2bParamsFunc?.addParam(defaultValue?.value);
        } else {
            // eslint-disable-next-line
            defaultValue?.map((el) => {
                b2bParamsFunc?.addParam(el.value);
            })
        }
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line
    }, []);
    const dynamicWidth = windowWidth * 0.6; // Adjust the multiplier as needed


    return (
        <div
            id="dropDownWrapper"
            style={{width: dynamicWidth, maxWidth: '400px', margin: "0 auto 25px auto"}}
        >
            <Select
                id="dropDownSelect"
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#8f00ff" : "#cccccc",
                        boxShadow: state.isFocused ? "0 0 0 1px #8f00ff" : "",
                        borderWidth: "2px",
                        borderRadius: "16px",
                        paddingLeft: "0",
                        paddingRight: "0",
                        paddingTop: "8px",
                        paddingBottom: "5px",
                        backgroundColor: "inherit",
                    }),
                    multiValue: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "10px",
                        backgroundColor: "#cd9df2",
                        color: "#333",
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: state.isFocused ? "#8f00ff" : "inherit",
                        color: state.isFocused ? "#fff" : "#fff",
                        borderRadius: state.isFocused ? "16px" : "",
                    }),
                    menu: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: "16px",
                        backgroundColor: "#363537",
                    }),
                }}
                isSearchable={false}
                isMulti
                defaultValue={defaultValue}
                className="basic-multi-select"
                classNamePrefix="select"
                options={options}
                components={{
                    Option,
                    MultiValueRemove,
                    ClearIndicator,
                }}
                b2bParamsFunc={b2bParamsFunc}
            />
        </div>
    );
}
